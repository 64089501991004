@import url('https://fonts.googleapis.com/css?family=Nunito:300,500,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body, h1, h2, p, div, span, li, ul {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #041119;
    color: white;
    /* font-family: Montserrat, sans-serif; */
    font-size: 12px;
}

h1 {
    text-transform: uppercase;
}


.SearchBox {
    border: none;
    border-radius: 0px;
    padding: 5px 0;
    width: 100%;
    border-bottom: 2px solid white;
    background-color: transparent;
    color: white;
    font-size: 2em;
    outline: none;
}

.Result {
    margin: 3px 6px 3px 0;
    border: 1px solid rgba(102, 113, 136, 0.39);
    border-left: 3px solid #ffbfa3;
    background-color: rgba(48, 67, 104, 0.5);
    border-top-right-radius: 10px;
    padding: 4px 10px;
    width: 150px;
    
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
}


.RemoveButton {
    float: right;
    cursor: pointer;
}

.SmallButton {
    cursor: pointer;
    font-size: 0.8em;
}

.Highlights {
    text-align: center;
}


@media only screen and (max-width: 999px) {
    .Info {
        position: absolute;
        top: 10px;
        left: 10px;
    }
    
    .Search {
        position: absolute;
        top: 60px;
        left: 10px;
        width: 120px;
    }
        
    .Selected {
        position: absolute;
        right: 10px;
        top: 40px;
        width: 120px;
    }

    .Highlights {
        position: absolute;
        bottom: 10px;
        width: 100%;
    }

    .Hint { font-size: 0.9em;}
    .Highlight { font-size: 3em;}

    .ResultsWrapper {
        max-height: 80vh;
        overflow: hidden;
        width: 150px;
    }
}

@media screen and (min-width: 1000px) {

    .Info {
        position: absolute;
        top: 100px;
        left: 50px;
    }
    
    .Search {
        position: absolute;
        top: 180px;
        left: 50px;
        width: 200px;
    }
        
    .Selected {
        position: absolute;
        right: 50px;
        top: 180px;
        width: 200px;
    }
    
    .Highlights {
        position: absolute;
        top: 10px;
        width: 100%;
    }

    .Hint { font-size: 0.9em;}
    .Highlight { font-size: 3em;}
       
    .ResultsWrapper {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        height: 60vh;
        max-width: 70vw;
    }
}